@charset "UTF-8";

// -------------------------------------------------------------------------------------
// Typography
// -------------------------------------------------------------------------------------

@mixin font-style(
  $font-fam,
  $xs,
  $sm,
  $md,
  $lg,
  $xl,
  $letter-sp,
  $line-h,
  $weight,
  $style
) {
  font-family: $font-fam;
  font-size: $xs;
  letter-spacing: $letter-sp;
  line-height: $line-h;
  font-weight: $weight;
  font-style: $style;
  font-stretch: normal;
  @include media-breakpoint-up(sm) {
    font-size: $sm;
  }
  @include media-breakpoint-up(md) {
    font-size: $md;
  }
  @include media-breakpoint-up(lg) {
    font-size: $lg;
  }
  @include media-breakpoint-up(xl) {
    font-size: $xl;
  }
}

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=7f43de08-a25c-447f-89ee-6d1321484029&fontids=903184,903196");
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/Helvetica_stauss_processform_Web/MTI-WebFonts-1272248942/Fonts/903184/b8765d4b-d9a3-48b9-ac65-560e7517cf0e.woff2")
      format("woff2"),
    url("../assets/fonts/Helvetica_stauss_processform_Web/MTI-WebFonts-1272248942/Fonts/903184/d7d2e6c6-fc3a-41a5-9b52-648e12e215b2.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/Helvetica_stauss_processform_Web/MTI-WebFonts-966165360/Fonts/903196/3e8a8b56-3cb0-4347-b670-eaaf06b76e9b.woff2")
      format("woff2"),
    url("../assets/fonts/Helvetica_stauss_processform_Web/MTI-WebFonts-966165360/Fonts/903196/07173950-fa69-4b65-9c71-0cf2ec00b51d.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

$font-sans: "Helvetica Neue", sans-serif;

// ------------------ font type   xs     sm     md     lg     xl     lsp      lh      w     style
@mixin font-l {
  @include font-style(
    $font-sans,
    28px,
    28px,
    28px,
    28px,
    28px,
    -0.02em,
    1.25em,
    300,
    normal
  );
}
@mixin font-m {
  @include font-style(
    $font-sans,
    14px,
    16px,
    16px,
    16px,
    16px,
    -0.01em,
    1.5em,
    300,
    normal
  );
}
@mixin font-s {
  @include font-style(
    $font-sans,
    11px,
    12px,
    12px,
    12px,
    12px,
    0.01em,
    1.5em,
    300,
    normal
  );
  // 1.3em in vertical?
}
.font-l {
  @include font-l;
}
.font-m {
  @include font-m;
}
.font-s {
  @include font-s;
}
