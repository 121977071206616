// -------------------------------------------------
// BOOTSTRAP
// Override default variables before the import
$body-bg: #eee;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
// -------------------------------------------------

// -------------------------------------------------
// Variables
// -------------------------------------------------

$color: #3f37d9;
$bg: #000;
$bg-light: rgba(#000, 0.15);

$c1: #292c78;
$c2: #e15e2a;
$c3: #5e6662;
$c4: #84bf41;
$c5: #803d5c;
$c6: #bb9927;

$header-h: 60px;
$space: 20px;
$vbar-w: 80px;
$max-text-w-em: 40em;

// -------------------------------------------------------------------------------------
// Imports (after variables)
// -------------------------------------------------------------------------------------

@import ".~bootstrap/scss/_functions.scss";
@import ".~bootstrap/scss/_variables.scss";
@import ".~bootstrap/scss/mixins/_breakpoints.scss";
// @import './style/_boogrid';
@import "./_typography";
@import "./_reset";
@import "./_mixins";

// -------------------------------------------------
// Common
// -------------------------------------------------


html,
body {
  height: 100vh;
  width: 100vw;
  position: relative;
  @include font-m;
  color: white;
  background-color: $bg;
}

body.noscroll {
  overflow: hidden;
}

a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: white;
  }
  &.active {
    color: white !important;
  }
}

.text-webpblpage {
  @include font-m;
  max-width: $max-text-w-em;
  // margin-right: $space * 2;
  h2,
  h3,
  p,
  div,
  span,
  a,
  b {
    @include font-m;
  }
  &.small {
    @include font-s;
    h2,
    h3,
    p,
    div,
    span,
    a,
    b {
      @include font-s;
    }
  }
}

.lazy-img {
  margin-bottom: $space;
  line-height: 0;
  &.loading {
    background-color: rgba(#fff, 0.05);
    min-height: 450px;
  }
}

#spinner {
  z-index: 1;
  position: fixed;
  top: 50vh;
  left: 50vw;
  pointer-events: none;
  transform: translate(-50%, -50%);
  @include transition(opacity 400ms);
  svg.dot {
    transform: scale(3);
    circle {
      fill: $c1;
      @include animation("spinner-animation 5s infinite");
    }
  }
}

.text-margin {
  // text margin --------------------
  padding-left: 15px;
  @include media-breakpoint-up(md) {
    padding-left: 142px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 0;
  }
  // ----------------------------------
}

.responsive-video-embed {
  margin: $space 0;
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: black;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    background-color: black;
  }
}

// -------------------------------------------------
// Helpers
// -------------------------------------------------

strong {
  font-weight: 500 !important;
}
.pointer {
  cursor: pointer;
}
.white50 {
  color: rgba(white, 0.5);
}

// -------------------------------------------------
// Elements
// -------------------------------------------------

#content {
  // scrollbars fix 1
  // max-height: 100vh;
  // overflow-y: auto;

  // position: relative;
  // min-height: 100vh;

  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;

  z-index: 1;
  padding-left: $vbar-w;
  @include media-breakpoint-up(xl) {
    padding-left: calc(50% + #{$vbar-w*0.5});
  }
  @include transition(filter 400ms, opacity 400ms);
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  // &[data-loading="true"],
  // &[data-grid-visible="true"],
  // &[data-menu-open="true"] {
  &[data-blurred="true"] {
    filter: blur(10px);
    opacity: 0.1;
    pointer-events: none;
  }
  &[data-loading="true"] {
    #spinner {
      opacity: 1;
    }
  }
  .text-webpblpage {
    padding-right: $space;

    // v1
    // margin: $space * 1.5 0;
    // margin-top: calc(#{$space * 1.5} - 5px); // ADJUST_SPACING
    // &:first-child { margin-top: 0; }

    // v2
    margin-bottom: $space * 1.5;
    margin-top: -5px;

    // text margin --------------------
    padding-left: 15px;
    @include media-breakpoint-up(md) {
      padding-left: 142px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 0;
    }
    // ----------------------------------
  }
  .compensate-bottom-margin {
    margin-top: -35px;
  }
  .compensate-top-margin {
    margin-top: -$space * 1.5;
  }

  .item,
  .list,
  .page {
    // padding-top: $header-h;
    padding-top: calc(#{$header-h} + var(--grid-size) * 1.5);

    padding-bottom: 60px; // $vbar-w;
    .imgs-wrapper {
      margin-bottom: -$space;
    }
    .links {
      margin-top: $space * 0.5;
      margin-bottom: -3px; // ADJUST_SPACING
      a {
        @include font-s;
      }
      // text margin --------------------
      padding-left: 15px;
      @include media-breakpoint-up(md) {
        padding-left: 142px;
      }
      @include media-breakpoint-up(xl) {
        padding-left: 0;
      }
      // ----------------------------------
      &.share button {
        color: rgba(white, 0.5) !important;
      }
      &.share button:hover {
        color: white !important;
      }
    }
    .imgs-wrapper + .links {
      margin-top: $space * 1.5;
    }
  }

  .list {
    pointer-events: none;
    // margin-top: calc(1.35 * var(--grid-size) + 2px);
    margin-bottom: -4px;
    .list-item {
      a {
        pointer-events: all;
        display: inline-block;
        position: relative;
        margin-left: 15px;
        @include font-s;
        @include media-breakpoint-up(md) {
          margin-left: 142px;
        }
        @include media-breakpoint-up(xl) {
          margin-left: $space;
        }
        &::before {
          position: absolute;
          top: 0;
          left: -$space;
          content: "–";
        }
        &:hover {
          opacity: 1 !important;
        }
      }
    }
    &:hover .list-item a {
      opacity: 0.5;
    }
  }

  .homepage {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      // opacity: 0.7;
    }
    .text {
      @include linearGradientV(rgba(#0e0e18, 0), rgba(#0e0e18, 0.3));
      position: relative;
      padding: 200px $vbar-w $vbar-w $space;
      // opacity: 0.8;
    }
    @include media-breakpoint-down(lg) {
      opacity: 0;
    }
  }

  .category-landing {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      opacity: 0.7;
    }
    .text {
      @include linearGradientV(rgba(#0e0e18, 0), rgba(#0e0e18, 0.75));
      padding: 200px $vbar-w $vbar-w $space;
      position: relative;
      opacity: 0.8;
    }
    @include media-breakpoint-down(lg) {
      opacity: 0;
    }
  }

  .ad-landing {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;

    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      opacity: 0.7;
    }
    .text {
      @include linearGradientV(rgba(#0e0e18, 0), rgba(#0e0e18, 0.9));
      // opacity: 0.8;
      padding: 200px $space $space;
      position: relative;
      width: 55%;
      height: 50vh;
      -moz-hyphens: auto;
      z-index: 2;
      hyphens: auto;
      p:not(:first-child) {
        text-indent: $space;
      }
    }
    .info {
      @include linearGradientV(rgba(#0e0e18, 0), rgba(#0e0e18, 0.9));
      padding: 200px 0 $space;
      position: relative;
      width: 45%;
      height: 50vh;
      > * {
        position: absolute;
        right: 0;
        width: 200px;

        .btn-wrapper {
          @include box-shadow(4px, 4px, 8px, rgba(0, 0, 0, 0.5));

          button.contact-us {
            opacity: 0.8;
            background: -webkit-linear-gradient(
              left,
              rgba(#fff, 0) 0%,
              rgba(#fff, 1) 70%,
              rgba(#fff, 1) 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
              to right,
              rgba(#fff, 0) 0%,
              rgba(#fff, 1) 70%,
              rgba(#fff, 1) 100%
            );
            &:hover {
              opacity: 1;
            }
            width: 200px;
            border: none;
            font-weight: 300;
            font-size: $font-size-lg;
            padding: $space * 0.5 $space * 2;
            text-align: right;
          }
        }
      }
      div.contact {
        padding: $space * 2 0px $space * 0.5;
        a:hover {
          text-decoration: underline;
        }
      }
      div.imprint {
        opacity: 0.5;
        a:hover {
          text-decoration: underline;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      opacity: 0;
    }
    .page.pb-0 {
      padding-bottom: 0px;
    }
    .page.pt-0 {
      padding-top: 0px;
    }
    .potty {
      position: absolute;
      left: -9999px;
    }
    .hidden {
      display: none;
    }
    .noshow {
      opacity: 0;
    }
    input[type="text"],
    input[type="email"],
    textarea {
      border: none;
      outline: none;
      padding: 0px $space * 1.5;
      margin: 2px;
      width: calc(100% - 4px);
      background-color: rgba(255, 255, 255, 0.15);
      color: white;
      font-weight: 300;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 300;
      }
    }
    .fieldset {
      input {
        width: calc(50% - 4px) !important;
      }
    }
    .intro {
      padding-left: $space * 1.5 + 2px;
      margin-bottom: $space * 0.25;
    }
    /* Customize the label (the container) */
    .checkbox {
      display: block;
      position: relative;
      padding-left: $space * 1.5;
      margin: 2px 2px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: rgba(255, 255, 255, 0.15);
    }

    /* On mouse-over, add a grey background color */
    .checkbox:hover input ~ .checkmark {
      background-color: rgba(255, 255, 255, 0.15);
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox input:checked ~ .checkmark {
      background-color: rgba(255, 255, 255, 0.15);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox .checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  #fix-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    padding-bottom: $space;
    padding-right: $space;
    @include media-breakpoint-up(md) {
      padding-right: $vbar-w;
    }
  }
}

[id^="fixed-bg-img-"] {
  /*DEBUG background-color: rgba(teal, 0.3);*/
  background-color: rgba(white, 0.1);
  z-index: 1;
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  @include media-breakpoint-up(xl) {
    display: block;
  }
  @include transition(filter 400ms, opacity 400ms);
  &.blurred {
    filter: blur(10px);
    opacity: 0.1;
  }
}

#fixed-bg-img-left {
  left: 0;
  right: calc(50% + #{$vbar-w*0.5});
  background-size: cover;
  background-position: center center;
}

#fixed-bg-img-right {
  left: calc(50% + #{$vbar-w*0.5});
  right: 0;
  background-size: cover;
  background-position: center center;
}

#app-bg {
  z-index: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: 100% 100%;
  background-image: url("../assets/images/bg.png");
}

#ui-fixed {
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  > * {
    pointer-events: all;
  }

  #header {
    position: relative;
    z-index: 1;
    /*DEBUG background-color: rgba(red, 0.1);*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $space;
    @include media-breakpoint-up(md) {
      padding: 0 $vbar-w;
    }
    height: $header-h;
    @include linearGradientV(rgba(#0e0e18, 0.6), rgba(#0e0e18, 0));
    .left,
    .right {
      /*DEBUG background-color: rgba(yellow, 0.7);*/
      display: flex;
      align-items: center;
      height: $header-h;
      width: calc(50% - #{$vbar-w*0.5});
    }
    .left {
      justify-content: flex-start;
      @include media-breakpoint-down(sm) {
        width: $vbar-w - $space * 2;
        justify-content: center;
      }
      // #logo-pt1, #logo-pt2 { cursor: pointer; }
      #logo-pt2 {
        position: absolute;
        height: $header-h;
        display: flex;
        align-items: center;
        left: $vbar-w + 15px;
        top: 0;
        @include media-breakpoint-up(md) {
          left: 222px;
        }
      }
    }
    .right {
      // @include media-breakpoint-down(sm) { width: 80px; }
      justify-content: space-between;
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
      @include media-breakpoint-up(xl) {
        justify-content: space-between;
      }
      .l,
      .r {
        display: flex;
        align-items: center;
      }
      .l {
        margin-left: -10px;
        margin-right: 10px;
      }
      #dots {
        width: 40px;
        height: 40px;
        margin-right: $space*0.5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:before {
          height: 30px;
          transform: scale(1.1);
          content: url("../assets/images/dots.svg");
          @include media-breakpoint-down(xs) {
            transform: scale(0.9);
          }
        }
      }
    }
    .arrows-mobile {
      /*DEBUG background-color: rgba(yellow, 0.1);*/
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: var(--grid-size);
      top: $header-h;
      left: 86px;
      img {
        @include media-breakpoint-down(xs) {
          transform: scale(0.8);
        }
      }
    }
  }

  #vertical-bar {
    /*DEBUG background-color: rgba(yellow, 0.1); */
    position: absolute;
    left: 0;
    top: 0;
    height: $vbar-w;
    width: 100vh;
    padding-left: 60px; // $vbar-w;

    // padding-right: $header-h;
    padding-right: calc(#{$header-h} + var(--grid-size) * 1.5);

    // --- real vh    width: calc(var(--real-vh, 1vh) * 100);

    transform: rotate(-90deg);
    transform-origin: 50vh 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    hyphens: none;
    @include media-breakpoint-up(xl) {
      left: calc(50vw - #{$vbar-w * 0.5});
    }
    @include font-s;
    .bottom {
      width: 50%;
      max-width: 50%;
      padding-right: $space;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .year {
      text-align: center;
      opacity: 0.5;
      transform: rotate(+90deg);
    }
    .center {
      width: 82%;
      text-align: right;
      transform: scale(2);
    }
    .top {
      width: 40%;
      text-align: right;
    }
  }

  #menu {
    /*DEBUG background-color: rgba(teal, 0.3);*/
    z-index: 5;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    right: 0;
    top: $header-h;
    bottom: 0;
    padding: 0 $space $space 0;
    padding-top: calc(var(--grid-size) * 1.5);
    text-align: right;
    transform: translateX(150%);
    overflow-y: auto;
    @include media-breakpoint-up(md) {
      padding-right: $vbar-w;
    }
    @include transition(transform 200ms);
    a:hover {
      text-decoration: none;
    }
    .main {
      a:not(.font-s) {
        @include font-l;
      }
      margin-bottom: $space * 2;
      input {
        // search
        @include font-l;
        color: white;
        padding: 0;
        text-align: right;
        background: none;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
    .social {
      @include font-s;
      a {
        @include font-s;
      }
      margin-bottom: $space;
    }
    .spacer {
      flex-grow: 1;
    }
    .bottom {
      a {
        display: inline-block;
        margin-left: $space;
        @include font-s;
      }
    }
    &[data-open="true"] {
      transform: translateX(0);
    }
  }

  #grid-wrapper {
    // /*DEBUG*/ background-color: rgba(white, 0.1);

    margin-top: calc(var(--grid-size) * 1);

    position: absolute;
    left: $vbar-w;
    top: $header-h;
    right: $space;
    bottom: 0;
    @include media-breakpoint-up(md) {
      right: $vbar-w;
    }
    @include media-breakpoint-up(xl) {
      right: calc(50% + #{$vbar-w*0.5});
    }
    @include transition(opacity 400ms);
    &[data-grid-visible="false"] {
      opacity: 0;
      pointer-events: none;
    }

    #grid-thumbs {
      pointer-events: none;
    }
    #grid .item svg {
      pointer-events: none;
    }
    #grid,
    #grid-thumbs {
      @include transition(opacity 400ms);
      position: absolute;
      top: 0;
      display: grid;
      /*****************************/
      width: calc(100% + var(--grid-size));
      /*****************************/
      grid-template-columns: repeat(auto-fill, minmax(var(--grid-size), 8.2fr));
      /*****************************/
      margin-left: calc(var(--grid-size) * -0.5);
      /*****************************/
      margin-right: calc(var(--grid-size) * -0.5);

      &::after {
        content: "";
        flex: auto;
      }

      .item {
        // justify-self: end;
        /*****************************/
        justify-self: center;

        height: var(--grid-size);
        width: var(--grid-size);
        min-height: var(--grid-size);
        min-width: var(--grid-size);

        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: relative;

        // _---------------------------____-___--_--__-__---
        // _---   MOVED HERE     ------____-___--_--__-__---
        // _---------------------------____-___--_--__-__---

        position: relative;
        z-index: 2;
        svg.dot circle {
          fill: white;
        }
        @include transition(opacity 800ms 50ms);
        &:hover,
        &.active,
        &[data-touched="yes"] {
          svg.dot {
            transform: scale(3);
            circle {
              stroke-width: 0.5px;
            }
          }
          &[data-category="graphic"] svg.dot circle {
            fill: $c1;
          }
          &[data-category="spatial"] svg.dot circle {
            fill: $c2;
          }
          &[data-category="signage"] svg.dot circle {
            fill: $c3;
          }
          &[data-category="product"] svg.dot circle {
            fill: $c4;
          }
          &[data-category="interface"] svg.dot circle {
            fill: $c5;
          }
          &[data-category="furniture"] svg.dot circle {
            fill: $c6;
          }
        }

        // &.touched {
        //   svg.dot {
        //     transform: scale(6);
        //     circle {
        //       stroke-width: 0.25px;
        //       stroke: white;
        //     }
        //   }
        //   &::after {
        //     content: url("../images/touched-arrow.svg");
        //     z-index: 2;
        //     position: absolute;
        //     display: inline-flex;
        //     width: 6px;
        //     height: 6px;
        //     top: -15.5px;
        //   }
        // }

        &:hover,
        &[data-touched="yes"] {
          svg.dot circle {
            stroke: white;
          }
        }

        &.hide {
          opacity: 0.2;
          pointer-events: none;
        }

        .thumb {
          opacity: 0.9;
          // --- Mouseover ----------------------------
          @include transition(opacity 8000ms 500ms);
          // --- Mouseover ----------------------------
          opacity: 0;
          &[data-visible="yes"] {
            // --- Mouseout ----------------------------
            @include transition(opacity 400ms 10ms);
            // --- Mouseout ----------------------------
            opacity: 1;
          }

          display: inline-block;
          height: calc(var(--grid-size) * 3);
          width: calc(var(--grid-size) * 3);
          position: absolute;
          // pointer-events: none;

          background-color: $bg-light;
          background-size: cover;
          background-position: center center;
          &.left {
            left: calc(var(--grid-size) * 0.5);
          }
          &.right {
            right: calc(var(--grid-size) * 0.5);
          }
          &.top {
            top: calc(var(--grid-size) * 0.5);
          }
          &.bottom {
            bottom: calc(var(--grid-size) * 0.5);
          }

          &.no-right {
            right: auto !important;
            left: calc(var(--grid-size) * 0.5) !important;
          }
          &.no-left {
            left: auto !important;
            right: calc(var(--grid-size) * 0.5) !important;
          }
          &.no-bottom {
            bottom: auto !important;
            top: calc(var(--grid-size) * 0.5) !important;
          }
          &.no-top {
            top: auto !important;
            bottom: calc(var(--grid-size) * 0.5) !important;
          }

          // ---------------------------------------------
          // Not square

          background-size: contain;
          background-repeat: no-repeat;
          background-position: left top;
          background-color: transparent;

          // x•
          // ••
          &.left.top,
          &.left.bottom.no-bottom,
          &.top.right.no-right,
          &.bottom.no-bottom.right.no-right {
            background-position: top left;
          }

          // •x
          // ••
          &.right.top,
          &.right.bottom.no-bottom,
          &.top.left.no-left,
          &.bottom.no-bottom.left.no-left {
            background-position: top right;
          }

          // ••
          // x•
          &.left.bottom,
          &.left.top.no-top,
          &.bottom.right.no-right,
          &.top.no-top.right.no-right {
            background-position: bottom left;
          }

          // ••
          // •x
          &.right.bottom,
          &.right.top.no-top,
          &.bottom.left.no-left,
          &.top.no-top.left.no-left {
            background-position: bottom right;
          }

          // ---------------------------------------------

          &.debug {
            border: 1px solid green;
          }
        }
        // _---------------------------____-___--_--__-__---
        // _---   MOVED HERE     ------____-___--_--__-__---
        // _---------------------------____-___--_--__-__---
      }
    }
  }

  #nav-dots {
    /*DEBUG background-color: rgba(yellow, 0.1);*/
    position: relative;
    z-index: 19;
    pointer-events: none;
    // display: flex;
    // justify-content: flex-end;
    // padding-right: $space;
    // @include media-breakpoint-up(md) { padding-right: $vbar-w; }
    padding: 0 $space 0 $vbar-w;
    @include media-breakpoint-up(md) {
      padding-right: $vbar-w;
    }

    @include media-breakpoint-up(xl) {
      position: fixed;
      padding-left: $vbar-w;
      padding-right: calc(50vw + #{$vbar-w * 0.5});
    }

    /*****************************/
    display: grid;
    /*****************************/
    width: calc(100% + var(--grid-size));
    /*****************************/
    grid-template-columns: repeat(auto-fill, minmax(var(--grid-size), 8.2fr));
    /*****************************/
    margin-left: calc(var(--grid-size) * -0.5);
    /*****************************/
    margin-right: calc(var(--grid-size) * -0.5);

    a.nav-item {
      /*****************************/
      justify-self: center;

      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: var(--grid-size);
      width: var(--grid-size);
      min-height: var(--grid-size);
      min-width: var(--grid-size);

      svg.dot {
        transform: scale(3);
      }
      &[data-category="graphic"] {
        @include media-breakpoint-down(lg) {
          grid-column: -7;
        }
        svg.dot circle {
          fill: $c1;
        }
      }
      &[data-category="spatial"] {
        @include media-breakpoint-down(lg) {
          grid-column: -6;
        }
        svg.dot circle {
          fill: $c2;
        }
      }
      &[data-category="signage"] {
        @include media-breakpoint-down(lg) {
          grid-column: -5;
        }
        svg.dot circle {
          fill: $c3;
        }
      }
      &[data-category="product"] {
        @include media-breakpoint-down(lg) {
          grid-column: -4;
        }
        svg.dot circle {
          fill: $c4;
        }
      }
      &[data-category="interface"] {
        @include media-breakpoint-down(lg) {
          grid-column: -3;
        }
        svg.dot circle {
          fill: $c5;
        }
      }
      &[data-category="furniture"] {
        @include media-breakpoint-down(lg) {
          grid-column: -2;
        }
        svg.dot circle {
          fill: $c6;
        }
      }
      &:hover,
      &.active,
      &.active-fixed {
        svg.dot circle {
          stroke: white;
          stroke-width: 0.5px;
        }
      }
    }

    &.hide-on-start {
      opacity: 0;
    }
  }

  // .nav-item {
  // svg.dot { transform: scale(3); }
  // &[data-category="graphic"] {
  //   @include media-breakpoint-down(lg) { grid-column: -7; }
  //   svg.dot circle { fill: $c1; }
  // }
  // &[data-category="spatial"] {
  //   @include media-breakpoint-down(lg) { grid-column: -6; }
  //   svg.dot circle { fill: $c2; }
  // }
  // &[data-category="signage"] {
  //   @include media-breakpoint-down(lg) { grid-column: -5; }
  //   svg.dot circle { fill: $c3; }
  // }
  // &[data-category="product"] {
  //   @include media-breakpoint-down(lg) { grid-column: -4; }
  //   svg.dot circle { fill: $c4; }
  // }
  // &[data-category="interface"] {
  //   @include media-breakpoint-down(lg) { grid-column: -3; }
  //   svg.dot circle { fill: $c5; }
  // }
  // &[data-category="furniture"] {
  //   @include media-breakpoint-down(lg) { grid-column: -2; }
  //   svg.dot circle { fill: $c6; }
  // }
  // &:hover, &.active, &.active-fixed {
  //   svg.dot circle {
  //     stroke: white;
  //     stroke-width: 0.5px;
  //   }
  // }
  // }
}

// -------------------------------------------------
// Hamburger
// -------------------------------------------------

$hb-w: 22px;
$hb-h: 16px;
$hb-line-weight: 2px;
$hb-color: white;
$hb-color-hover: #eee;
$hb-color-on: white;
$hb-color-on-hover: #eee;
#hamburger {
  z-index: 1;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: relative;
  height: $hb-h;
  width: $hb-w;
  -webkit-user-select: none;
  user-select: none;

  @include media-breakpoint-down(xs) {
    transform: scale(0.8);
  }

  input {
    z-index: 2;
    display: block;
    position: absolute;
    width: $hb-w;
    height: $hb-h;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    -webkit-touch-callout: none;
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg);
      background: $hb-color-on;
    }
    &:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg);
    }
    &:hover ~ span {
      background-color: $hb-color-hover;
    }
    &:checked:hover ~ span {
      background-color: $hb-color-on-hover;
    }
  }
  span {
    z-index: 1;
    position: absolute;
    display: block;
    top: $hb-h*0.5 - $hb-line-weight*0.5;
    left: 0;
    width: $hb-w;
    height: $hb-line-weight;
    background-color: $hb-color;
    border-radius: $hb-line-weight*0.5;
    @include transition(transform 200ms, background 200ms, opacity 200ms);
    &:nth-last-child(3) {
      transform: translate(0, -$hb-h*0.5);
    }
    &:nth-last-child(2) {
      transform: translate(0, 0);
    }
    &:last-child {
      transform: translate(0, $hb-h*0.5);
    }
  }
}

// -------------------------------------------------
// Cookie banner
// -------------------------------------------------

.cookie-consent-outer {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  padding: $space;
  pointer-events: none;
}

.cookie-consent {
  padding: $space;
  background-color: darken(rgb(53, 53, 53), 6%);
  max-width: 680px;
  z-index: 999;
  @include font-s;
  pointer-events: all;

  /* Customize the label (the container) */
  .checkbox {
    display: block;
    position: relative;
    padding-left: $space * 1.3;
    margin: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ .checkmark {
      background-color: rgba(255, 255, 255, 0.15);
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: rgba(255, 255, 255, 0.15);

        &:after {
          opacity: 1;
          transform: rotate(45deg);
        }
      }

      &:disabled {
        ~ span,
        ~ .checkmark {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .checkmark, span {
      cursor: pointer;
      transition: opacity 0.2s ease;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: rgba(255, 255, 255, 0.15);

      &:after {
        content: "";
        position: absolute;
        display: block;
        opacity: 0;
        left: 7px;
        top: 3.25px;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transition: opacity 0.2s, transform 0.1s ease 0.05s;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-start;
    }

    .buttons {
      flex-shrink: 0;
      margin-left: 32px;

      @include media-breakpoint-down(xs) {
        margin-left: -4px;
        margin-top: 20px;
      }
    }

    .checkboxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px 8px;
      margin-top: 8px;
    }

    details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &[open] {
        summary:after {
          transform: rotate(225deg);
          top: 7px;
        }
      }

      summary {
        list-style: none;
        display: block;
        padding-right: $space * 1.3;
        position: relative;
        margin: 2px;
        max-width: max-content;

        &::after {
          height: 8px;
          width: 8px;
          border: solid white;
          border-width: 0 1.5px 1.5px 0;
          content: "";
          position: absolute;
          transform: rotate(45deg);
          top: 3px;
          right: 4px;
          transition: top 0.1s, transform 0.1s ease;
        }
      }
    }
  }

  .button-decline {
    @include font-s;
    border: 0;
    color: white;
    background: transparent;
    opacity: 0.5;
    margin-right: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  .button-accept {
    background-color: rgba(white, 0.1) !important;
    border: 0;
    color: white;
    @include font-s;
  }
}

// -------------------------------------------------
// Animations
// -------------------------------------------------

@include keyframes(spinner-animation) {
  // 0%    { fill: $c1; }
  2.4% {
    opacity: 1;
    fill: $c1;
  }
  8.4% {
    opacity: 0.4;
  }
  14.4% {
    opacity: 1;
    fill: $c2;
  }
  // 16.4% { fill: $c2; }
  19.0% {
    opacity: 1;
    fill: $c2;
  }
  25.0% {
    opacity: 0.4;
  }
  31.0% {
    opacity: 1;
    fill: $c3;
  }
  // 32.9% { fill: $c3; }
  36.0% {
    opacity: 1;
    fill: $c3;
  }
  42.0% {
    opacity: 0.4;
  }
  48.0% {
    opacity: 1;
    fill: $c4;
  }
  // 49.7% { fill: $c4; }
  53.0% {
    opacity: 1;
    fill: $c4;
  }
  59.0% {
    opacity: 0.4;
  }
  65.0% {
    opacity: 1;
    fill: $c5;
  }
  // 66.5% { fill: $c5; }
  70.0% {
    opacity: 1;
    fill: $c5;
  }
  76.0% {
    opacity: 0.4;
  }
  82.0% {
    opacity: 1;
    fill: $c6;
  }
  // 83.3% { fill: $c6; }
  87.0% {
    opacity: 1;
    fill: $c6;
  }
  93.0% {
    opacity: 0.4;
  }
  99.0% {
    opacity: 1;
    fill: $c1;
  }
}
